import React, { FC } from 'react'

import { Carousel } from '@juullabs/react-components'
import { EntryContent } from '@viewlio/juulio-bridge/src/common/components/contentful/EntryContent'
import { EntryLayout } from '@viewlio/juulio-bridge/src/common/components/contentful/EntryLayout'
import { CollectionSection as CollectionSectionType } from '@viewlio/types/src/contentful'
import cx from 'classnames'
import { useIntl } from 'react-intl'

import { Entry } from 'components/contentful/Entry'
import { EntryCallToActions } from 'components/contentful/EntryCallToActions'
import { markdown } from 'utils/markdown'

import styles from './CollectionSection.module.scss'

export const CollectionSection: FC<CollectionSectionType> = ({
  allowMobileColumns,
  backgroundColor,
  body,
  columnsPerRow,
  elementId,
  fontTheme,
  isCarouselOnTablet,
  fullBleed,
  itemAlignment,
  items,
  links,
  removePadding,
  title,
  classNames = {},
}) => {
  const intl = useIntl()
  const sectionClasses = cx('collection-section', {
    [`collection-section--alignment-${itemAlignment}`]: itemAlignment,
  }, styles.section, classNames.section)

  const itemsClasses = cx('collection-section__items', {
    [`collection-section__items--${columnsPerRow}-per-row`]: columnsPerRow,
    'collection-section__items--mobile-columns': allowMobileColumns,
    [styles.hideOnTablet]: isCarouselOnTablet,
  }, classNames.items)

  const itemClasses = cx('collection-section__item', styles.container, classNames.item)

  return (
    <EntryLayout
      backgroundColor={backgroundColor}
      elementId={elementId}
      removePadding={removePadding}
      fullBleed={fullBleed}
      className={classNames.entryLayout}
      renderEntry={_layout => (
        <div className={sectionClasses}>
          {(body || title) && (
            <EntryContent
              body={markdown(body)}
              fontTheme={fontTheme}
              heading={title}
              className={classNames.entryContent}
            />
          )}

          <div className={itemsClasses}>
            {items.map(({ contentType, ...item }) => (
              <div className={itemClasses} key={item.id}>
                <Entry contentType={contentType} gridChild={true} {...item} />
              </div>
            ))}
          </div>

          {
            isCarouselOnTablet && (
              <div className={styles.showOnTablet} data-testid='collection-section-carousel'>
                <Carousel
                  ariaLabels={{
                    nextButton: intl.formatMessage({ id: 'a11y.carousel.next_button' }),
                    prevButton: intl.formatMessage({ id: 'a11y.carousel.prev_button' }),
                  }}
                  showPaginationButtons={false}
                  spaceBetween={8}>
                  {items.map(({ contentType, ...item }) => (
                    <div className={itemClasses} key={item.id}>
                      <Entry
                        key={item.id}
                        contentType={contentType}
                        {...item}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            )
          }

          <EntryCallToActions links={links} />
        </div>
      )}
    />
  )
}
