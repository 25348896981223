import React, { FC } from 'react'

import { CallToAction, FormattedEntry } from '@viewlio/types/src/contentful'
import cx from 'classnames'

import { EntryCallToAction } from 'components/contentful/EntryCallToAction'

import styles from './EntryCallToActions.module.scss'

export type Props = {
  buttonDirection?: 'row' | 'column'
  className?: string
  fontTheme?: 'dark' | 'light' | 'gray'
  gridChild?: boolean
  links?: FormattedEntry<CallToAction>[]
}

export const EntryCallToActions: FC<Props> = ({
  buttonDirection = 'column',
  className,
  fontTheme,
  gridChild,
  links = [],
}) => {
  if (!links.length) {
    return null
  }

  return (
    <div
      className={cx(
        styles.container,
        className,
        {
          [styles.row]: buttonDirection === 'row',
          [styles.column]: buttonDirection === 'column',
          [styles.gridChild]: gridChild,
        },
      )}
      data-preload-links
    >
      {links.map(({ id, ...linkProps }, index) => (
        <EntryCallToAction
          id={id}
          key={id}
          buttonProps={{
            theme: fontTheme === 'light' ? 'dark' : fontTheme === 'dark' ? 'light' : null,
            variant: index === 1 ? 'ghost' : 'filled',
          }}
          {...linkProps}
        />
      ))}
    </div>
  )
}
