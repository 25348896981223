import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

export type Layout = {
  constrainedWithoutPadding: boolean;
  constrainedWithPadding: boolean;
  fullBleedWithoutPadding: boolean;
  fullBleedWithPadding: boolean;
  gridChild: boolean;
  gridChildWithPadding: boolean;
};

export type LayoutProps = {
  backgroundColor?: string;
  className?: string;
  /** An optional prop that will adds an ID attribute to the outermost div so
   * that you can target it through other means.
   */
  elementId?: string;
  /** The fullBleed option will stretch the background color across the entire
   * width of the viewport.
   */
  fullBleed?: boolean;
  /** Set this value to true if this component is being displayed inside of a
   * grid. This will force the component to use its mobile layout. Setting this
   * option to `false` will constrain the width of this component to our
   * standard content-wrapper size.
   */
  gridChild?: boolean;
  /** removePadding will allow images placed inside the container to stretch all
   * the way to the top and bottom of the component. Only use this setting if
   * you can guarantee that the image will be taller than the content.
   */
  removePadding?: boolean;
};

export type Props = {
  /** This is a render prop that will return the layout object determined by
   * this component that can be used in the entry component being rendered.
   */
  renderEntry(layout: Layout): ReactNode;
} & LayoutProps;

/** This component servers as a wrapper around entries that come back from
 * Contentful. We have a number of entries that all use identical layouts that
 * are configured by the same sets of fields in Contentful.
 */
export const EntryLayout: FC<Props> = ({
  backgroundColor = 'transparent',
  className,
  elementId,
  fullBleed = true,
  gridChild = false,
  renderEntry,
  removePadding = false,
}) => {
  const layout = {
    constrainedWithPadding: !fullBleed && !removePadding && !gridChild,
    constrainedWithoutPadding: !fullBleed && removePadding && !gridChild,
    fullBleedWithPadding: fullBleed && !removePadding && !gridChild,
    fullBleedWithoutPadding: fullBleed && removePadding && !gridChild,
    gridChild,
    gridChildWithPadding: gridChild && !removePadding,
  };

  const outerClasses = classNames('entry-layout', className, {
    'content-wrapper': !fullBleed && !gridChild,
    'entry-layout--constrained-with-padding': layout.constrainedWithPadding,
    'entry-layout--constrained-without-padding':
      layout.constrainedWithoutPadding,
    'entry-layout--full-bleed-with-padding': layout.fullBleedWithPadding,
    'entry-layout--full-bleed-without-padding': layout.fullBleedWithoutPadding,
  });

  const wrapperClasses = classNames('entry-layout__wrapper', {
    'entry-layout__wrapper--with-padding': layout.constrainedWithPadding,
  });

  // The element that we apply the background color to depends on whether or not
  // the component is stretching across the entire viewport
  const backgroundColorStyles =
    fullBleed || gridChild
      ? {
          outer: { backgroundColor },
          wrapper: { backgroundColor: 'transparent' },
        }
      : {
          outer: { backgroundColor: 'transparent' },
          wrapper: { backgroundColor },
        };

  return (
    <div
      className={outerClasses}
      id={elementId}
      style={backgroundColorStyles.outer}
    >
      <div
        className={classNames({ 'content-wrapper': fullBleed && !gridChild })}
      >
        <div className={wrapperClasses} style={backgroundColorStyles.wrapper}>
          {renderEntry(layout)}
        </div>
      </div>
    </div>
  );
};
