import React, { FC, ReactNode } from 'react';

import classNames from 'classnames';

export type Props = {
  /** The body content can contain markup as a string as it will be dangerously
   * rendered onto the page. We need to do this to support Markdown from
   * Contentful.
   */
  body?: string;
  className?: string;
  fontTheme?: 'light' | 'gray' | 'dark';
  heading?: string;
  /** This component is purely presentational so instead of providing data for
   * the component to figure out how to render you supply it a render prop for
   * call to actions. This component will send back the classes required for
   * styling purposes.
   */
  renderCallToActions?(className: string): ReactNode;
  subheading?: string;
};

/** This component standardizes how a few of our Contentful entries handle and
 * display their data. We have a few models that share the same fields in
 * Contentful and this serves as a unified way of handling their display logic.
 */
export const EntryContent: FC<Props> = ({
  body,
  className,
  fontTheme = 'dark',
  heading,
  renderCallToActions = () => null,
  subheading,
}) => {
  const classes = classNames('entry-content', className, {
    'entry-content--gray': fontTheme === 'gray',
    'entry-content--light': fontTheme === 'light',
  });

  const callToActions = renderCallToActions(
    classNames('entry-content__call-to-action button--full-width', {
      'button--inverted': fontTheme === 'light' || fontTheme === 'gray',
    })
  );

  return (
    <div className={classes}>
      {!!subheading && (
        <h3 className="entry-content__subheading subheading--small">
          {subheading}
        </h3>
      )}
      {!!heading && (
        <h2 className="entry-content__heading heading--medium">{heading}</h2>
      )}
      {!!body && (
        <div
          className="entry-content__body markdown-content"
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      )}

      {!!callToActions && (
        <div className="entry-content__call-to-actions">{callToActions}</div>
      )}
    </div>
  );
};
